<template>
  <section class="container" >
    <section class="business__box" :style="{backgroundImage:`url(${businessBg})`}">
      <div class="business__wrap inner">
        <h3 v-if="BusinessData.catinfo" class="business__tt" v-html="BusinessData.catinfo.description"></h3>
        <ul class="business__wl">
          <li v-for="(item, index) in BusinessData.list" :key="item.catid" @mouseenter="slideActive(index)" :class="{'active': index == activeIndex}">
            <router-link :to="'/businessView/'+item.catid">
              <div class="business__top">
                <figure><img :src="item.thumb" alt=""/></figure>
                <div class="business__ico" :style="{backgroundImage:`url(${businessiico1})`}" v-if="index == 0"></div>
                <div class="business__ico" :style="{backgroundImage:`url(${businessiico2})`}" v-if="index == 1"></div>
                <div class="business__ico" :style="{backgroundImage:`url(${businessiico3})`}" v-if="index == 2"></div>
                <strong>{{item.name}}</strong>
              </div>
              <div class="business__copmany">
                <strong>运营企业</strong>
                <div class="businee__li">
                  <p v-for="itemsub in item.list" :key="itemsub.cid"><span>{{itemsub.title}}</span></p>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <Footer/>
  </section>
</template>

<script>
import Footer from '../components/Footer';
export default {
  data() {
    return {
      businessBg: require('../assets/images/business.png'),
      businessiico1: require('../assets/images/businessico1.png'),
      businessiico2: require('../assets/images/businessico2.png'),
      businessiico3: require('../assets/images/businessico3.png'),
      BusinessData: {},
      activeIndex: 0
    }
  },
  components:{
    Footer
  },
  methods: {
    async getBusiness(){
      const {data: res} = await this.$http.post("getBusinessNew");
      if(res.status == 1){
        this.BusinessData = res.data;
      }
    },
    slideActive(index) {
      this.activeIndex = index
    }
  },
  mounted() {
    this.getBusiness();
  },
}
</script>

<style>

</style>